import classNames from 'classnames'
import type { FunctionComponent, HTMLProps } from 'react'
import { forwardRef, useRef } from 'react'
import type { FilePlayerProps } from 'react-player/file'
import FilePlayer from 'react-player/file'

import { useDidMount } from '../../lib/hooks/useDidMount'
import { tracker } from '../../lib/store/tracker/useTracker'
import type { WithTestId } from '../../lib/utils/testid'
import type { VideoType } from '../../types/video'
import { Play } from '../icons/play'
import styles from './video.module.scss'

/**
 * Uses react-player to display a video.
 * Lazy: only shows the poster unless the user clicks on it.
 */
export const Video: FunctionComponent<React.PropsWithChildren<Props>> = ({
    sources,
    poster,
    alt = '',
    width,
    height,
    preset,
    className,
    'data-testid': testId,
    playing = true,
    loop = false,
    ...restOfProps
}) => {
    const mounted = useDidMount()
    const playerRef = useRef<FilePlayer | null>(null)

    const posterImage = (
        <img
            role='poster'
            className={styles.poster}
            src={poster}
            width={width}
            height={height}
        />
    )

    // dont render the video player on the server side. see: https://github.com/cookpete/react-player/issues/1474
    if (!mounted) {
        return posterImage
    }

    if (preset === 'gif') {
        if (!mounted)
            return (
                <div className={classNames(styles.video, className)}>
                    {posterImage}
                </div>
            )

        return (
            <video
                muted
                loop
                playsInline
                webkit-playsinline='true'
                autoPlay
                controls={false}
                width={width}
                height={height}
                className={classNames(styles.video, className)}
                poster={poster}
                title={alt}
            >
                {sources.map(({ source, contentType }) => (
                    <source key={source} src={source} type={contentType} />
                ))}
            </video>
        )
    }

    return (
        <figure
            className={classNames(styles.wrapper, className)}
            role='video'
            data-testid={testId}
        >
            <FilePlayer
                stopOnUnmount
                playing={playing}
                controls
                alt={alt}
                light={poster}
                playIcon={
                    <Play
                        width='75'
                        height='75'
                        data-testid='video.play'
                        className={styles.play}
                        color='step-right-before-midnight'
                        secondaryColor='step-white'
                    />
                }
                wrapper={Wrapper}
                url={sources.map(({ source, contentType }) => ({
                    src: source,
                    type: contentType,
                }))}
                ref={playerRef}
                onPlay={() => tracker.videoPlayed(alt)}
                onEnd={() => tracker.videoEnded(alt)}
                onPause={() =>
                    tracker.videoPaused(
                        alt,
                        playerRef.current?.getCurrentTime()
                    )
                }
                loop={loop}
                {...restOfProps}
            />
            {/** This is a hack to set the size of the container. It's not visible. */}
            <div className={styles.poster} style={{ width, height }}>
                {posterImage}
            </div>
        </figure>
    )
}

const Wrapper = forwardRef<HTMLDivElement, HTMLProps<HTMLElement>>(
    ({ children, alt }, ref) => (
        <div
            className={styles.player}
            title={alt}
            ref={ref}
            data-testid='video.wrapper'
        >
            {children}
        </div>
    )
)

type Props = WithTestId<
    Partial<FilePlayerProps> & VideoType & { className?: string }
>

import type { FunctionComponent } from 'mdx/types'

import { CardFlip } from '../../components/card-flip'

export enum DynamicElement {
    StepBlackCardFlip = 'step-black.card-flip',
}

export const DynamicElements = {
    [DynamicElement.StepBlackCardFlip]: CardFlip,
}

const DynamicElementComponent: FunctionComponent<{ type: string }> = ({
    type,
}) => {
    const Component = DynamicElements[type]

    if (!Component) return null

    return <Component />
}

export default DynamicElementComponent

import { observer } from 'mobx-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'

import { LinkButton } from '../../components/button/link'
import type { PRESETS } from '../../components/button/presets'
import type {
    ButtonTrackingProps,
    CommonButtonProps,
} from '../../components/button/props'
import type { ActionLocalPathnameFragment } from '../../graphql/fragments/ActionLocalPathnameFragment.graphql'
import type { WithTestId } from '../../lib/utils/testid'
import { LINK } from '../../shared/util/link'

/** Action button that navigates the current website. */
export const ActionLocalPathname: FunctionComponent<Props> = observer(
    ({
        title,
        'data-testid': testId,
        placement,
        analyticsKey,
        containerElementId,
        path,
        preset = 'primary',
        size,
        ...restOfProps
    }) => {
        const link = LINK.cms({ path, ...restOfProps })
        const router = useRouter()

        return (
            <Link
                passHref
                href={link.route}
                as={link.serialize(router)}
                legacyBehavior
            >
                <LinkButton
                    preset={preset ? preset : 'primary'}
                    size={size ?? 'medium'}
                    data-testid={testId}
                    analyticsKey={analyticsKey}
                    placement={placement}
                    disabled={!path}
                >
                    {title}
                </LinkButton>
            </Link>
        )
    }
)

type Props = WithTestId<Omit<ActionLocalPathnameFragment, 'type' | 'sys'>> &
    ButtonTrackingProps & {
        preset?: keyof typeof PRESETS | string
        size?: CommonButtonProps['size']
    }
